<template>
  <div>
    <div class="row">

      <!-- Patient Data -->
      <div class="col-md-3">
        <Card v-bind:title="'Pasien'">
          <template v-slot:body>
            <div class="d-flex align-items-center">
              <div class="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center">
                <div style='height: 100px; width: 100px; position: relative'>
                  <img
                    class="image"
                    :src="patient.photo"
                  >
                </div>
                <i class="symbol-badge bg-success"></i>
              </div>
              <div>
                <a class="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary">{{ patient.name }}</a>
                <div class="text-muted">Gol. Darah: {{ patient.blood_type }}</div>
                <div class="mt-2">
                  <a
                    class="btn btn-sm btn-success font-weight-bold mr-2 py-2 px-3 px-xxl-5 my-1"
                    @click="$router.push({ path: '/patient/detail/' + patient.id })"
                  >Detail</a>
                </div>
              </div>
            </div>
            <div class="py-9">
              <div class="d-flex mb-2">
                <span class="font-weight-bold mr-2">Jenis Kelamin:</span>
                <span class="text-muted text-hover-primary">{{ patient.gender }}</span>
              </div>
              <div class="d-flex mb-2">
                <span class="font-weight-bold mr-2">Tanggal Lahir:</span>
                <span class="text-muted text-hover-primary">{{ patient.display_birt_date }}</span>
              </div>
              <div class="d-flex mb-2">
                <span class="font-weight-bold mr-2">Email:</span>
                <span class="text-muted text-hover-primary">{{ patient.email }}</span>
              </div>
              <div class="d-flex mb-2">
                <span class="font-weight-bold mr-2">Handphone:</span>
                <span class="text-muted text-hover-primary">{{ patient.mobile_phone }}</span>
              </div>
              <div class="d-flex mb-2">
                <span class="font-weight-bold mr-2">Alamat:</span>
                <span class="text-muted text-hover-primary">{{ patient.address }}</span>
              </div>
            </div>
          </template>
        </Card>
      </div>

      <!-- History Section -->
      <div class="col-md-9">
        <Card v-bind:title="`Riwayat ${typeof patient.name !== 'undefined' ? patient.name : ''}`">
          <template v-slot:body>
            <div v-if="dataLoaded == true">
              <b-tabs class="mt-n3 patient-history-tabs">

                <!-- Perjanjian -->
                <b-tab
                  title="Perjanjian"
                  active
                >
                  <AppointmentHistory />
                </b-tab>

                <!-- Riwayat -->
                <b-tab title="Rekam Medis">
                  <TreatmentHistory />
                </b-tab>

                <!-- Resep -->
                <b-tab title="Resep">
                  <PrescriptionHistory />
                </b-tab>

                <!-- Laboratorium -->
                <b-tab title="Lab">
                  <LaboratoriumHistory />
                </b-tab>

                <!-- Dokumen -->
                <b-tab title="Dokumen">
                  <DocumentHistory
                    purpose="history"
                    :dataId="patient.id"
                  />
                </b-tab>

                <!-- Bangsal -->
                <b-tab title="Bangsal">
                  <BedHistory :bedData="patient.id" />
                </b-tab>

                <!-- Waktu -->
                <b-tab title="Waktu">
                  <TimelineHistory />
                </b-tab>

              </b-tabs>
            </div>
          </template>
        </Card>
      </div>

    </div>
  </div>
</template>

<script>

import Card from "@/view/content/Card.vue"
import AppointmentHistory from '@/component/patients/AppointmentHistory.vue'
import TreatmentHistory from '@/component/patients/TreatmentHistory.vue'
import PrescriptionHistory from '@/component/patients/PrescriptionHistory.vue'
import LaboratoriumHistory from '@/component/patients/LaboratoriumHistory.vue'
// import DocumentHistory from '@/component/patients/DocumentList.vue'
import DocumentHistory from '@/view/pages/patients/fileCard.vue'
import BedHistory from '@/component/beds/Table.vue'
import TimelineHistory from '@/component/patients/TimelineHistory.vue'
import module from '@/core/modules/CrudModule.js'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  name: 'History',

  components: {
    Card,
    AppointmentHistory,
    TreatmentHistory,
    PrescriptionHistory,
    LaboratoriumHistory,
    DocumentHistory,
    BedHistory,
    TimelineHistory
  },

  data() {
    return {
      // Patient Data
      patient: {},
      dataLoaded: false
    }
  },

  methods: {

    async getPatient() {
      this.patient = await module.get('patients/' + this.$route.params.id)
      if (this.patient == null) {
        this.$router.push('/patient/list')
      } else {
        this.dataLoaded = true
      }
    }

  },

  mounted() {
    // Breadcrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Pasien", route: "" },
      { title: "Daftar Pasien", route: "/patient/list" },
      { title: "Riwayat" },
    ]);
    // Get Patient
    this.getPatient()
  },

}
</script>

<style>
.patient-history-tabs .nav-tabs {
  overflow-x: auto;
  overflow-y: hidden;
  flex-wrap: nowrap;
}
.patient-history-tabs .nav-tabs .nav-link {
  white-space: nowrap;
}
</style>