<template>
  <div>

    <Table
      :items="items"
      :itemExcel="itemExcel"
      :itemPopUp="itemPopUp"
      :fields="fields"
      :hasPatient="true"
      :perPage="perPage"
      :currentPage="currentPage"
      :totalRows="totalRows"
      @appointmentOnStatusChange="pagination"
      @filter="generalFilter"
      @btnDeleteOnClick="btnDeleteOnClick"
      @pageOnClick="pageOnClick"
      @btnExportOnClick="btnExportOnClick" />

  </div>
</template>

<script>

import Card from '@/view/content/Card.vue'
import Table from '@/component/appointments/Table.vue'
import module from '@/core/modules/CrudModule.js'
import moment from 'moment'
import { getUser } from "@/core/services/jwt.service.js"

export default {

  components: {
    Table,
    Card
  },

  data() {
    return {
      currentUser: getUser(),
      filter: {
        appointment_status_id: '',
        doctor_id: '',
        start_date: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        end_date: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
      },
      perPage: 20,
      currentPage: 1,
      totalRows: 0,
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "display_date",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "time",
          label: "Waktu",
          sortable: true,
        },
        {
          key: "doctor_name_action_type_name",
          label: "Dokter & Jenis Tindakan",
          sortable: true,
        },
        {
          key: "appointment_status_name",
          label: "Status",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      fieldPopUp: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "display_date",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "time",
          label: "Waktu",
          sortable: true,
        },
        {
          key: "patient_name",
          label: "Pasien",
          sortable: true,
        },
        {
          key: "doctor_name_action_type_name",
          label: "Dokter & Jenis Tindakan",
          sortable: true,
        },
        {
          key: "appointment_status_name",
          label: "Status",
          sortable: true,
        },
      ],
      items: [],
      itemExcel: [],
      itemPopUp: [],
    }
  },

  methods: {

    pageOnClick(evt, page) {
      evt.preventDefault()
      this.currentPage = page
      this.pagination()
    },

    async pagination() {
      if(this.currentUser.user_type_id == 1){
        this.filter.doctor_id = this.currentUser.id
      }
      let filterParams = `&patient_name=&appointment_status_id=${this.filter.appointment_status_id}&doctor_id=${this.filter.doctor_id}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`
      let response = await module.paginate(`appointments/get-by-patient/${this.$route.params.id}`, `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`)
      let pagination = response.meta.pagination
      this.items = response.data
      this.totalRows = pagination.total
    },

    async getExport() {
      if(this.currentUser.user_type_id == 1){
        this.filter.doctor_id = this.currentUser.id
      }
      const excelRoute = 'appointment-excel'
      const popUpRoute = 'appointment-no-paginations'
      let filterParams = `&appointment_status_id=${this.filter.appointment_status_id}&patient_id=${this.$route.params.id}&doctor_id=${this.filter.doctor_id}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}&patient_name=`
      let responseExcel = await module.get(excelRoute, `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`)
      let responsePopUp = await module.get(popUpRoute, `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`)
      let excel = responseExcel.map(({number, ...rest}) => rest)
      this.itemExcel = excel
      this.itemPopUp = responsePopUp
    },

    setFilter(sent) {
      for (const key in this.filter) {
        this.filter[key] = sent[key]
      }
      if (
        this.filter.appointment_status_id != '' ||
        this.filter.doctor_id != '' ||
        this.filter.start_date != '' ||
        this.filter.end_date != ''
      )
        this.currentPage = 1
    },

    async generalFilter(sent) {
      this.setFilter(sent)
      await this.pagination()
    },

    async btnDeleteOnClick(id) {
      let result = await module.delete('appointments/' + id)
      if (result)
        this.pagination()
    },

    async btnExportOnClick(filter) {
      this.setFilter(filter)
      await this.getExport()
    }

  },

  mounted() {
    this.pagination()
  }

}
</script>