<template>
  <div>
    <!-- Filter -->
    <div
      :class="`row align-items-center justify-content-start ${
        hasPatient ? 'mt-3' : ''
      }`"
    >
      <div :class="hasPatient ? 'col-md-9' : 'col-md-6'">
        <span class="d-none d-sm-block">
          <b-input-group>
            <template #append>
              <b-button squared @click="filterByPeriode" variant="success"
                >Cari</b-button
              >
              <b-button squared @click="resetFilterPeriode" variant="danger"
                >Reset</b-button
              >
            </template>
            <b-form-input
              v-model="filter.start_date"
              type="text"
              placeholder="Tanggal Awal"
              autocomplete="off"
              readonly
            ></b-form-input>
            <b-input-group-append>
              <b-form-datepicker
                v-model="filter.start_date"
                button-only
                right
                reset-button
                locale="id"
                aria-controls="example-input"
              ></b-form-datepicker>
            </b-input-group-append>
            <b-button squared variant="light">s/d</b-button>
            <b-form-input
              v-model="filter.end_date"
              type="text"
              placeholder="Tanggal Akhir"
              autocomplete="off"
              readonly
            ></b-form-input>
            <b-input-group-append>
              <b-form-datepicker
                v-model="filter.end_date"
                button-only
                right
                reset-button
                locale="id"
                aria-controls="example-input"
              ></b-form-datepicker>
            </b-input-group-append>
          </b-input-group>
        </span>
        <span class="d-block d-sm-none">
          <div class="row">
            <div class="col-12 pb-1">
              <b-input-group>
                <template #append>
                  <b-button squared @click="filterByPeriode" variant="success"
                    >Cari</b-button
                  >
                  <b-button squared @click="resetFilterPeriode" variant="danger"
                    >Reset</b-button
                  >
                </template>
                <b-form-input
                  v-model="filter.start_date"
                  type="text"
                  placeholder="Tanggal Awal"
                  autocomplete="off"
                  readonly
                ></b-form-input>
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="filter.start_date"
                    button-only
                    right
                    reset-button
                    locale="id"
                    aria-controls="example-input"
                  ></b-form-datepicker>
                </b-input-group-append>
              </b-input-group>
            </div>
            <div class="col-12 pt-0">
              <b-input-group>
                <b-button squared variant="light">s/d</b-button>
                <b-form-input
                  v-model="filter.end_date"
                  type="text"
                  placeholder="Tanggal Akhir"
                  autocomplete="off"
                  readonly
                ></b-form-input>
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="filter.end_date"
                    button-only
                    right
                    reset-button
                    locale="id"
                    aria-controls="example-input"
                  ></b-form-datepicker>
                </b-input-group-append>
              </b-input-group>
            </div>
          </div>
        </span>
      </div>
      <div class="col-md-3">
        <treeselect
          v-if="currentUser.user_type_id != 1"
          v-model="filter.doctor_id"
          :multiple="false"
          :options="doctors"
          placeholder="Cari Berdasar Dokter"
          @select="filterByDoctor"
        />
      </div>
      <div class="col-md-3" v-if="!hasPatient">
        <b-input-group>
          <template #prepend>
            <b-input-group-text squared>
              <b-icon-search></b-icon-search>
            </b-input-group-text>
          </template>
          <b-form-input
            type="text"
            v-model="filter.patient_name"
            placeholder="Cari Nama Pasien"
            @keyup="filterByPatient"
          ></b-form-input>
        </b-input-group>
      </div>
    </div>

    <!-- Table -->
    <b-table
      striped
      class="mt-3"
      hover
      :items="items"
      :fields="fields"
      :style="'white-space: nowrap'"
      responsive
    >
      <template #cell(date)="data">
        {{ data.item.date.split(" ")[0] }}
      </template>
      <template #cell(doctor_name)="data">
        {{ data.item.doctor_name }}
      </template>
      <template #cell(display_medicine)="data">
        <table class="table">
          <tr>
            <th>Obat</th>
            <th>Dosis</th>
            <th>Frekuensi</th>
            <th>Hari</th>
            <th>Instruksi</th>
          </tr>
          <tr
            v-for="(medicine, index) in data.item.display_medicine"
            :key="index"
          >
            <td>{{ medicine.name }}</td>
            <td>{{ medicine.dosage }}</td>
            <td>{{ medicine.frequency }}</td>
            <td>{{ medicine.days }}</td>
            <td>{{ medicine.instruction }}</td>
          </tr>
        </table>
      </template>
      <template #cell(patient_name)="data">
        <span v-if="data.item.sub_patient_id == null">
          {{ data.item.patient_name }}
        </span>
        <span v-if="data.item.sub_patient_id != null">
          {{ data.item.patient_name + " - " + data.item.sub_patient_name }}
        </span>
      </template>

      <template #cell(redeem_type)="data">
        <b-badge
          style="background-color: #f47b20; color: #ffffff"
          pill
          v-if="data.item.redeem_type == 0"
          >Internal (Pembayaran)</b-badge
        >
        <b-badge
          pill
          style="background-color: #245590; color: #ffffff"
          v-if="data.item.redeem_type == 1"
          >Internal (Apotek)</b-badge
        >
        <b-badge
          style="background-color: #ffffff"
          pill
          v-if="data.item.redeem_type == 2"
          >Eksternal</b-badge
        >
      </template>

      <template #cell(redeem_status_display)="data">
        <template v-if="data.item.redeem_type != 2">
          <b-badge
            pill
            style="background-color: #ffc107"
            v-if="data.item.redeem_status == 0"
            >{{ data.item.redeem_status_display }}</b-badge
          >
          <b-badge
            pill
            style="background-color: #28a745; color: #ffffff"
            v-if="data.item.redeem_status == 1"
            >{{ data.item.redeem_status_display }}</b-badge
          >
        </template>
        <template v-else> - </template>
      </template>
      <!-- <template #cell(display_medicine)="data">
        {{ displayMedicine(data.item.display_medicine) }}
      </template> -->
      <template #cell(actions)="data">
        <template
          v-if="
            !selectPrescription &&
            data.item.redeem_status == 0 &&
            data.item.redeem_type != 2
          "
        >
          <b-button
            size="sm"
            class="mr-1 btn-primary text-center"
            variant="primary"
            v-b-tooltip.hover
            title="Pembayaran Obat"
            @click="btnSelectedItem(data.item.id, data.item.redeem_type)"
            ><i class="fas fa-cash-register"></i
          ></b-button>
        </template>
        <template v-if="hasPatient && !selectPrescription">
          <b-button
            size="sm"
            class="mr-1 btn-success"
            v-b-tooltip.hover
            title="Detail"
            v-b-modal.modal-print
            @click="btnDetailOnClick(data.item.id)"
            ><i class="fas fa-eye px-0"></i
          ></b-button>
          <b-button
            size="sm"
            class="btn-info"
            v-b-tooltip.hover
            title="Download"
            @click="btnDownloadOnClick(data.item.id)"
            ><i class="fas fa-download px-0"></i
          ></b-button>
        </template>
        <template v-if="selectPrescription">
          <b-button
            size="sm"
            class="mr-1 btn-primary"
            variant="primary"
            @click="selectedItem(data.item.id)"
            >Pilih</b-button
          >
        </template>
        <template v-if="!hasPatient && !selectPrescription">
          <b-button
            size="sm"
            class="mr-1 btn-info"
            v-b-tooltip.hover
            title="Detail"
            @click="
              $router.push({ path: '/prescriptions/detail/' + data.item.id })
            "
            ><i class="fas fa-eye px-0"></i
          ></b-button>
          <b-button
            size="sm"
            class="mr-1 btn-success"
            v-b-tooltip.hover
            title="Edit"
            v-if="manipulateBtn == true"
            @click="
              $router.push({ path: '/prescriptions/edit/' + data.item.id })
            "
            ><i class="fas fa-edit px-0"></i
          ></b-button>
          <b-button
            size="sm"
            class="btn-danger"
            v-b-tooltip.hover
            title="Hapus"
            v-if="manipulateBtn == true"
            @click="btnDeleteOnClick(data.item.id)"
            ><i class="fas fa-trash px-0"></i
          ></b-button>
        </template>
      </template>
    </b-table>
    <b-pagination
      v-if="items.length != 0"
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      @page-click="pageOnClick"
      class="mt-4"
    ></b-pagination>
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import Treeselect from "@riophae/vue-treeselect";
import { debouncer } from "@/core/modules/Helper.js";
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { getUser } from "@/core/services/jwt.service.js";

export default {
  props: {
    items: Array,
    fields: Array,
    hasPatient: Boolean,
    perPage: Number,
    currentPage: Number,
    totalRows: Number,
    selectPrescription: Boolean,
  },

  components: {
    Card,
    Treeselect,
  },

  data() {
    return {
      // Filter
      filter: {
        doctor_id: "",
        patient_name: "",
        start_date: "",
        end_date: "",
      },
      // Options
      doctors: [],
      // access management
      manipulateBtn: false,
      // current user
      currentUser: getUser(),
    };
  },

  methods: {
    pageOnClick(evt, page) {
      evt.preventDefault();
      this.$emit("pageOnClick", page);
    },

    async getDoctorsOption() {
      let response = await module.setDoctorTreeSelect("doctors");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.doctors = response.data;
        this.doctors.unshift({
          label: "Filter Berdasar Dokter",
          id: "",
          isDisabled: true,
        });
      }
    },

    resetFilterPeriode() {
      this.filter = validation.clearForm(this.filter);
      if (this.currentUser.user_type_id == 1) {
        this.filter.doctor_id = this.currentUser.id;
      }
      this.$emit("resetFilter", this.filter);
      this.$emit("filterByPeriode", this.filter);
    },

    filterByPeriode() {
      // if (this.filter.patient_name != "") {
      //   this.currentPage = 1
      //   }else{
      //   this.currentPage = this.lastCurrentPage
      // }
      this.$emit("filterByPeriode", this.filter);
    },

    filterByPatient() {
      // if (this.filter.patient_name != "" || this.filter.doctor_id != "" || this.filter.start_date != '' || this.filter.end_date != '') {
      //   this.currentPage = 1
      //   }else{
      //   this.currentPage = this.lastCurrentPage
      // }
      debouncer(() => {
        this.$emit("filterByPatient", this.filter);
      }, 500);
    },

    async filterByDoctor(evt) {
      // if (this.filter.patient_name != "" || this.filter.doctor_id != "" || this.filter.start_date != '' || this.filter.end_date != '') {
      //   this.currentPage = 1
      //   }else{
      //   this.currentPage = this.lastCurrentPage
      // }
      await this.filter.doctor_id;
      this.$emit("filterByDoctor", this.filter);
    },

    btnDetailOnClick(id) {
      this.$emit("btnDetailOnClick", id);
    },

    btnDownloadOnClick(id) {
      this.$emit("btnDownloadOnClick", id);
    },

    btnDeleteOnClick(id) {
      this.$emit("btnDeleteOnClick", id);
    },

    async selectedItem(id) {
      let response = await module.get("prescriptions/item/" + id);
      this.$emit("selectedItem", response, id);
    },

    async btnSelectedItem(id, type) {
      let response = await module.get("prescriptions/item/" + id);

      if (response.status_validation == 0) {
        if (type == 1) {
          window.data = response;
          window.id = id;
          this.$router.push("/pharmacy/cashier");
        } else if (type == 0) {
          this.$root.$emit("btnSelectedItem", response, id);
          this.$router.push(`/payment/add?prescription_id=${id}`);
        }
      } else {
        Swal.fire(
          "Terjadi Kesalahan",
          "Terdapat kesalahan dalam penulisan format frekuensi dosis , silahkan lakukan update atau penyesuaian untuk melanjutkan",
          "error"
        );
      }
    },

    displayMedicine(medicines) {
      let str = "";
      medicines.forEach(function (value, index) {
        str += value.name + " - " + value.dosage;
        if (index != medicines.length - 1) {
          if (medicines.length > 1) str += ", ";
        }
      });
      return str;
    },

    // access management
    async setActiveMenu() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);

      let a;
      for (a = 0; a < access_right.length; a++) {
        if (access_right[a] == "8002") {
          this.manipulateBtn = true;
        }
      }
    },
  },

  watch: {
    "filter.doctor_id": function (newVal, oldVal) {
      if (typeof newVal === "undefined") {
        this.filter.doctor_id = "";
        this.filterByDoctor();
      }
    },
  },

  mounted() {
    if (this.currentUser.user_type_id != 1) {
      // Get Options
      this.getDoctorsOption();
    }
    this.setActiveMenu();
  },
};
</script>

<style></style>