<template>
  <div>
    <Table
      :items="items"
      :perPage="perPage"
      :currentPage="currentPage"
      :totalRows="totalRows"
      @btnAddOnClick="btnAddOnClick"
      @btnEditOnClick="btnEditOnClick"
      @btnDeleteOnClick="btnDeleteOnClick"
      @filterByName="filterByName"
      @filter="generalFilter"
      @pageOnClick="pageOnClick"
    />

    <Form :form="form" :route="route" @formOnSubmit="formOnSubmit" />
  </div>
</template>

<script>
import Table from "@/component/case-managers/Table.vue";
import module from "@/core/modules/CrudModule.js";
import Form from "@/component/case-managers/Form.vue";
import { getUser } from "@/core/services/jwt.service.js";

export default {
  name: "TreatmentHistory",

  components: {
    Table,
    Form,
  },

  data() {
    return {
      currentUser: getUser(),
      // Form
      form: {
        patient_id: this.$route.params.id,
        date: "",
        title: "",
        case: "",
      },
      // Route
      route: "medical-records",
      // Filter
      filter: {
        name: "",
        start_date: "",
        end_date: "",
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 0,
      // Table Items
      items: [],
    };
  },

  methods: {
    pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      this.pagination();
    },

    async pagination() {
      let filterParams = `&name=${this.filter.name}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`;
      let route = `medical-records/get-by-patient/${this.$route.params.id}`;
      if (this.currentUser.user_type_id == 1) {
        route = `medical-records/get-by-doctor/${this.currentUser.id}`;
        filterParams += `&patient_id=${this.$route.params.id}`;
      }

      let response = await module.paginate(
        `${route}`,
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }

      this.items = response.data;
    },

    generalFilter(sended) {
      this.filter.name = sended.name;
      this.filter.start_date = sended.start_date;
      this.filter.end_date = sended.end_date;
      this.pagination();
    },

    filterByName(sended) {
      this.filter.name = sended.name;
      this.pagination();
    },

    formOnSubmit() {
      this.pagination();
    },

    btnAddOnClick() {
      this.route = "medical-records";
      delete this.form["_method"];
      this.form.patient_id = this.$route.params.id;
    },

    async btnEditOnClick(id) {
      this.route = `medical-records/${id}`;
      this.form = await module.get("medical-records/" + id);
      this.form["_method"] = "put";
    },

    async btnDeleteOnClick(id) {
      // Delete Data
      let result = await module.delete("medical-records/" + id);
      // If Deleted
      if (result) {
        this.pagination();
      }
    },
  },

  mounted() {
    // Get Table Data
    this.pagination();
  },
};
</script>

<style>
</style>