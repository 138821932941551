<template>
  <div class="row">
    <div class="col-12 mb-0 pb-0 mt-2">
      <div class="d-flex justify-content-end">
        <b-button variant="primary" @click="pagination"> Refresh </b-button>
      </div>
    </div>
    <div class="col-12 pt-0">
      <b-table
        striped
        hover
        responsive
        class="mt-3"
        :items="items"
        :fields="fields"
      >
        <template #cell(date)="data">
          {{
            new Date(data.item.date).toLocaleDateString("id-ID", {
              weekday: "long",
              day: "numeric",
              month: "numeric",
              year: "numeric",
            })
          }}
        </template>
        <template #cell(actions)="data">
          <b-button
            size="sm"
            class="mr-1 btn-info"
            v-b-tooltip.hover
            title="Detail"
            @click="detail(data.item)"
            ><i class="fas fa-eye px-0"></i
          ></b-button>
        </template>
      </b-table>
      <b-pagination
        v-if="items.length != 0"
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        @page-click="pageOnClick"
        class="mt-4"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import { getUser } from "@/core/services/jwt.service.js";

export default {
  name: "TimelineHistory",

  data() {
    return {
      currentUser: getUser(),
      filter: {
        doctor_id: "",
      },
      // pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 0,
      // table fields
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "date",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "description",
          label: "#",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      // table items
      items: [],
    };
  },

  methods: {
    pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      this.pagination();
    },

    async pagination() {
      if (this.currentUser.user_type_id == 1) {
        this.filter.doctor_id = this.currentUser.id;
      }
      let filterParams = `&doctor_id=${this.filter.doctor_id}`;
      let response = await module.paginate(
        `patient-timelines/${this.$route.params.id}`,
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.items = response.data;
      this.totalRows = pagination.total;
    },

    detail(data) {
      console.log(data);
      if(data.ref_table == "appointments"){
        this.$router.push(`/appointment/detail/${data.id}`)
      }else if(data.ref_table == "prescriptions"){
        this.$router.push(`/prescriptions/detail/${data.id}`)
      }else if(data.ref_table == "labs"){
        this.$router.push(`/lab-report-2/detail/${data.id}`)
      }else if(data.ref_table == "medical_records"){
        this.$router.push(`/patient/cases?resource=timeline&id=${data.id}`)
      }
    },
  },

  mounted() {
    this.pagination();
  },
};
</script>

<style>
</style>